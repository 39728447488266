<template>
<div>
  <div id="interactive" class="viewport scanner">
    <video />
    <canvas class="drawingBuffer" />
  </div>
  
</div>
</template>

<script>
import Quagga from '@ericblade/quagga2';

export default {
  name: 'QuaggaScanner',
  props: {
    onDetected: {
      type: Function,
      default(result) {
        console.log('detected: ', result);
      },
    },
    onProcessed: {
      type: Function,
      default(result) {
        let drawingCtx = Quagga.canvas.ctx.overlay;

        let drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute('width')),
              parseInt(drawingCanvas.getAttribute('height'))
            );
            result.boxes
              .filter(function(box) {
                return box !== result.box;
              })
              .forEach(function(box) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: 'green',
                  lineWidth: 2,
                });
              });
          }
          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: '#00F',
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              { x: 'x', y: 'y' },
              drawingCtx,
              { color: 'red', lineWidth: 3 }
            );
          }
        }
      },
    },
    readerTypes: {
      type: Array,
      default: () => ['code_128_reader'],
    },
    readerSize: {
      type: Object,
      default: () => ({
        width: { min: 640, ideal: 1920, max: 4096 },
        height: { min: 480, ideal: 1080, max: 2400 },
      }),
      validator: o =>
        typeof o.width === 'number' && typeof o.height === 'number',
    },
    aspectRatio: {
      type: Object,
      default: () => ({
        min: 1,
        max: 2,
      }),
      validator: o => typeof o.min === 'number' && typeof o.max === 'number',
    },
    facingMode: {
      type: String,
      default: () => 'environment'
    },
    cameras: {
      type: Array
    }

  },
  data: function() {
    console.log(this.cameras);
    return {
      quaggaState: {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: 1080,
            height: 1920,
            facingMode: this.facingMode,
            focusMode: {ideal: "continuous"},
            deviceId: this.cameras[this.cameras.length - 1].deviceId,
            // aspectRatio: { min: 1.7777777778, max: 1.9977777778 },
          },
          area: { // defines rectangle of the detection/localization area
            top: "20%",    // top offset
            right: "15%",  // right offset
            left: "15%",   // left offset
            bottom: "20%"  // bottom offset
          },
        },
        locator: {
          patchSize: 'x-small',
          halfSample: false,
        },
        frequency: 20,
        decoder: {
          readers: this.readerTypes,
        },
        locate: true,
      },
    };
  },
  watch: {
    onDetected: function(oldValue, newValue) {
      if (oldValue) Quagga.offDetected(oldValue);
      if (newValue) Quagga.onDetected(newValue);
    },
    onProcessed: function(oldValue, newValue) {
      if (oldValue) Quagga.offProcessed(oldValue);
      if (newValue) Quagga.onProcessed(newValue);
    },
  },
  mounted: function() {
    Quagga.init(this.quaggaState, function(err) {
      if (err) {
        return console.error(err);
      }
      Quagga.start();
    });
    Quagga.onDetected(this.onDetected);
    Quagga.onProcessed(this.onProcessed);
  },
  destroyed: function() {
    if (this.onDetected) Quagga.offDetected(this.onDetected);
    if (this.onProcessed) Quagga.offProcessed(this.offProcessed);
    Quagga.stop();
  },
};
</script>

<style scoped>
.viewport {
  position: relative;
  
}

.viewport canvas,
.viewport video {
  width: 95vw;
  height: 80vw;
  position: absolute;
  left: 0;
  top: 0;
}
</style>