<template>
  <div>

    <div class="kt-portlet kt-portlet--height-fluid">

      <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm pb-2">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">{{headertitle}}</h3>
        </div>
      </div>

      <div class="kt-portlet__body">
        <div class="iteration-navigation">
          <div
            class="previousIteration"
            v-if="this.selectedJournalIndex > 0"
            v-on:click="previousIteration()"
          >
            <i class="fas fa-angle-double-left"></i>
          </div>

          <div class="previousIteration" v-else>&nbsp;</div>

          <div class="iterationSelector">
            <multiselect v-model="selectedJournal" track-by="iteration" label="iteration" placeholder="Select one" :options="journals" :searchable="false" :allow-empty="false">
              <template slot="singleLabel" slot-scope="{ option }">Iteratie <strong>  {{ option.iteration }}</strong></template>
            </multiselect>
          </div>

          <div
            class="nextIteration"
            v-if="this.selectedJournalIndex < this.journals.length - 1"
            v-on:click="nextIteration()"
          >
            <i class="fas fa-angle-double-right"></i>
          </div>

          <div
            class="nextIteration"
            v-else-if="this.thisiteration != journals[selectedJournalIndex].iteration"
            v-on:click="addIteration()"
          >
            <i class="fas fa-plus"></i>
          </div>

          <div class="nextIteration" v-else>&nbsp;</div>
        </div>
      </div>

    </div>

    <div class="kt-portlet kt-portlet--height-fluid">
      <div class="kt-portlet__body">
        <div
          class="product-detail"
          v-if="journals[selectedJournalIndex].iteration != this.thisiteration"
        >
          <ul class="product-specs product-specs__1col product-specs__1col--noflex periodical-properties">

            <li class="captured_photo">
              <div>
                <i class="fas fa-image"></i>Foto
              </div>
              <div style="flex-direction: row-reverse;">
                <img :src="journals[selectedJournalIndex].image" alt />
                
              </div>
            </li>
          </ul>
          
          <ul class="product-specs product-specs__1col product-specs__1col--noflex periodical-properties">

            <li class="product-description">
              <div>
                <i class="fas fa-boxes"></i>Product omschrijving
              </div>
              <div class="data-field" v-for="productdescription in $attrs.productdescriptions" v-bind:key="productdescription.id">
                <ul class="ml-2" v-if="journals[selectedJournalIndex].product_description_id == productdescription.id">
                  {{productdescription.code}} - {{productdescription.name_nl}}
                </ul>
              </div>
            </li>

            <li class="iteration">
              <div>
                <i class="fas fa-calendar-alt"></i>Iteratie
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].iteration || "&nbsp;"}}</div>
            </li>

            <li class="regular-stock">
              <div>
                <i class="fas fa-warehouse-alt"></i>Aantal planten beschikbaar
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].stock}}</div>
            </li>

            <li class="height">
              <div>
                <i class="fas fa-layer-group"></i>Aantal lagen per CC
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].layers_per_cc}}</div>
            </li>

            <li class="height">
              <div>
                <i class="fas fa-arrows-alt-v"></i>Planthoogte
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].plantheight || "&nbsp;"}}</div>
            </li>

            <li class="pot-height">
              <div>
                <i class="fas fa-arrows-alt-v"></i>Belading in cm
              </div>
              <div
                class="data-field"
              >{{journals[selectedJournalIndex].plantheight_incl_pot || "&nbsp;"}}</div>
            </li>


            <li class="topper">
              <div>
                <i class="fas fa-stopwatch"></i>Topper
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].topper == 1 ? "ja" : "Nee" }}</div>
            </li>

            <li class="new">
              <div>
                <i class="fas fa-newspaper"></i>Nieuw
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].new == 1 ? "ja" : "Nee" }}</div>
            </li>

            <li class="fair_product">
              <div>
                <i class="fas fa-gavel"></i>Interne selectie
              </div>
              <div
                class="data-field"
              >{{journals[selectedJournalIndex].fair_product == 1 ? "ja" : "Nee" }}</div>
            </li>
            <li class="floriday_product">
              <div>
                <i class="fas fa-flower"></i>Floriday
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].floriday == 1 ? "ja" : "Nee" }}</div>
            </li>
            <li class="varb_product">
              <div>
                <i class="fas fa-flower"></i>Varb
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].varb == 1 ? "ja" : "Nee" }}</div>
            </li>

            <!-- <li class="sales-environment">
              <div>
                <i class="fas fa-thermometer-quarter"></i>verkoopomgeving
              </div>
              <div class="data-field"></div>
            </li> -->

            <li class="location">
              <div>
                <i class="fas fa-map"></i>Locatie
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].location}}</div>
            </li>

            <li class="status">
              <div>
                <i class="fas fa-toggle-on"></i>Status
              </div>
              <div class="data-field">{{product.productStateName}}</div>
            </li>

             <li class="sale_environment">
                <div><i class="fas fa-border-none"></i>Verkoopomgeving
                </div>
               <div class="data-field" v-for="saleenvironment in $attrs.saleenvironments" v-bind:key="saleenvironment.id">
                 <ul class="ml-2" v-if="journals[selectedJournalIndex].sale_environment_id == saleenvironment.id">
                   {{saleenvironment.name_nl}}
                 </ul>
               </div>
            </li>

            <li class="product-description">
              <div>
                <i class="fas fa-award"></i>Kwaliteitgroep
              </div>
              <div class="data-field" v-for="qualitygroup in $attrs.qualitygroups" v-bind:key="qualitygroup.id">
                <ul class="ml-2" v-if="journals[selectedJournalIndex].quality_group_id == qualitygroup.id">
                  {{qualitygroup.name}}
                </ul>
              </div>
            </li>

            <li class="product-description">
              <div>
                <i class="fas fa-seedling"></i>Rijpheidsstadium
              </div>
              <div class="data-field" v-for="maturitystage in $attrs.maturitystages" v-bind:key="maturitystage.id">
                <ul class="ml-2" v-if="journals[selectedJournalIndex].maturity_stage_id == maturitystage.id">
                  {{maturitystage.name}}
                </ul>
              </div>
            </li>

            <li class="spray">
              <div>
                <i class="fas fa-shower"></i>Gewasbeschermingsmiddel
              </div>
              <ul>
                <li
                  v-for="pesticide in journals[selectedJournalIndex].pesticides"
                  :key="pesticide.id">{{ pesticide.name }}</li>
              </ul>
            </li>

            <li class="remarks">
              <div>
                <i class="fas fa-comment"></i>Opmerking
              </div>
              <div class="data-field">{{journals[selectedJournalIndex].annotation}}</div>
            </li>

          </ul>
        </div>

        <div v-else class="product-detail--edit">
          <camera ref="cameraChild" :image="journals[selectedJournalIndex].image"></camera>

          <ul class="product-specs product-specs__1col periodical-properties">

            <li class="product-description">
              <div>
                <i class="fas fa-toggle-on"></i>Product omschrijving
              </div>
              <div class="data-field">
                <multiselect
                  v-model="selectedProductDescription"
                  :options="$attrs.productdescriptions"
                  :multiple= false
                  :custom-label="nameWithCode"
                  label="name_nl"
                  track-by= "code"
                  :close-on-select="true"
                  :searchable="true"
                  placeholder="Selecteer 1 omschrijving"
                  >
                  <template slot="singleLabel" slot-scope="{ option }">{{ option.code }} / {{ option.name_nl }}</template>
                </multiselect>
              </div>
            </li>

            <!--<li class="product-description">
              <div>
                <i class="fas fa-toggle-on"></i>Product omschrijving
              </div>
              <div class="data-field">
                <multiselect
                  v-model="selectedProductDescription"
                  :options="$attrs.productdescriptions"
                  >
                </multiselect>
              </div>
            </li>-->

            <li class="regular-stock">
              <div>
                <i class="fas fa-boxes"></i>Aantal planten beschikbaar
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].stock"
                  type="number"
                  value="0"
                />
              </div>
            </li>

            <li class="layers">
              <div>
                <i class="fas fa-layer-group"></i>Aantal lagen per CC
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].layers_per_cc"
                  type="number"
                />
              </div>
            </li>

            <li class="plantheight">
              <div>
                <i class="fas fa-calendar-alt"></i>Planthoogte
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].plantheight"
                  min="0"
                  type="number"
                />
              </div>
            </li>

            <li class="pot-height">
              <div>
                <i class="fas fa-arrows-alt-v"></i>Belading in cm
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].plantheight_incl_pot"
                  type="number"
                  min="0"
                />
              </div>
            </li>

            <li class="location">
              <div>
                <i class="fas fa-layer-group"></i>Locatie
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].location"
                  type="text"
                />
              </div>
            </li>

            <li class="remarks">
              <div>
                <i class="fas fa-comment"></i>Opmerking
              </div>
              <div class="data-field">
                <textarea class="form-control" v-model="journals[selectedJournalIndex].annotation"></textarea>
              </div>
            </li>

            <li class="spray">
              <div>
                <i class="fas fa-shower"></i>Gewasbeschermingsmiddel
              </div>
              <div class="data-field">
                <multiselect
                  v-model="selectedPesticides"
                  :options="$attrs.pesticides"
                  :multiple= true
                  label= "name"
                  track-by= "name"
                  :close-on-select="false"
                  placeholder="Selecteer 1 of meer middelen"
                  >
                </multiselect>
              </div>
            </li>

            <li class="new">
              <label for="new">
                <div>
                  <i class="fas fa-newspaper"></i>Nieuw
                </div>
                <div class="data-field">
                  <input
                    class="form-control"
                    v-model="journals[selectedJournalIndex].new"
                    type="checkbox"
                    id="new"
                  />
                </div>
              </label>
            </li>

            <li class="topper">
              <label for="topper">
                <div>
                  <i class="fas fa-newspaper"></i>Topper
                </div>
                <div class="data-field">
                  <input
                    class="form-control"
                    v-model="journals[selectedJournalIndex].topper"
                    type="checkbox"
                    id="topper"
                  />
                </div>
              </label>
            </li>

            <li class="fair_product">
              <label for="stock-exchange-plant">
                <div>
                  <i class="fas fa-star"></i>Interne selectie
                </div>
                <div class="data-field">
                  <input
                    class="form-control"
                    v-model="journals[selectedJournalIndex].fair_product"
                    type="checkbox"
                    id="stock-exchange-plant"
                  />
                </div>
              </label>
            </li>
            <li class="floriday_product">
              <label for="floriday">
                <div>
                  <i class="fas fa-newspaper"></i>Floriday
                </div>
                <div class="data-field">
                  <input
                    class="form-control"
                    v-model="journals[selectedJournalIndex].floriday"
                    type="checkbox"
                    id="floriday"
                  />
                </div>
              </label>
            </li>
            <li class="varb_product">
              <label for="varb">
                <div>
                  <i class="fas fa-newspaper"></i>Varb
                </div>
                <div class="data-field">
                  <input
                    class="form-control"
                    v-model="journals[selectedJournalIndex].varb"
                    type="checkbox"
                    id="varb"
                  />
                </div>
              </label>
            </li>
            <li class="status">
              <div>
                <i class="fas fa-toggle-on"></i>Verkoopomgeving
              </div>
              <div class="data-field">
                  <multiselect
                  v-model="selectedSaleEnvironment"
                  :options="$attrs.saleenvironments"
                  :multiple= false
                  label= "name_nl"
                  track-by= "name_nl"
                  :close-on-select="true"
                  placeholder="Selecteer een verkoopomgeving"
                  >
                </multiselect>
              </div>
            </li>
            <li class="status">
              <div>
                <i class="fas fa-toggle-on"></i>Kwaliteitgroep
              </div>
              <div class="data-field">
                   <multiselect
                  v-model="selectedQualityGroup"
                  :options="$attrs.qualitygroups"
                  :multiple= false
                  label= "name"
                  track-by= "name"
                  :close-on-select="true"
                  placeholder="Selecteer een status"
                  >
                </multiselect>
              </div>
            </li>
            <li class="status">
              <div>
                <i class="fas fa-toggle-on"></i>Rijpheidsstadium
              </div>
              <div class="data-field">
                   <multiselect
                  v-model="selectedMaturityStage"
                  :options="$attrs.maturitystages"
                  :multiple= false
                  label= "name"
                  track-by= "name"
                  :close-on-select="true"
                  placeholder="Selecteer een status"
                  >
                </multiselect>
              </div>
            </li>
            <li class="status">
              <div>
                <i class="fas fa-toggle-on"></i>Status
              </div>
              <div class="data-field">
                   <multiselect
                  v-model="selectedProductState"
                  :options="$attrs.productstates"
                  :multiple= false
                  label= "name"
                  track-by= "name"
                  :close-on-select="true"
                  placeholder="Selecteer een status"
                  >
                </multiselect>
              </div>
            </li>

            <!-- <li class="sales-environment">
              <div>
                <i class="fas fa-thermometer-quarter"></i>verkoopomgeving
              </div>
              <div class="data-field">
                <input
                  class="form-control"
                  v-model="journals[selectedJournalIndex].sale_environment_id"
                  type="number"
                  disabled
                />
              </div>
            </li> -->
            <li class="btn">
              <button
                class="form-control"
                @click="save()"
                style="background: #64a622; color:#fff"
              >Opslaan</button>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
  components: { Multiselect },
  props: ["product", "journals", "headertitle", "thisiteration"],
  data: function () {
    //
    return {
      selectedPesticides: null,
      selectedProductState: null,
      selectedQualityGroup: null,
      selectedMaturityStage: null,
      selectedSaleEnvironment: null,
      selectedProductDescription: null,
      selectedJournalIndex: 0,
      editVariable: "",
      editField: "",
      selectedJournal: null,
    };
  },
  directives: {
    focus: function (el) {
      Vue.nextTick(function () {
        el.focus();
      });
    },
  },
  watch: {
    selectedJournal(){
      if(this.journals.findIndex(x => x.iteration === this.selectedJournal.iteration) != this.selectedJournalIndex)
      {
        this.selectedJournalIndex = this.journals.findIndex(x => x.iteration === this.selectedJournal.iteration);
      }
      // this.selectedJournal = [];
    },
    selectedJournalIndex(){
      if(this.journals[this.selectedJournalIndex].iteration != this.selectedJournal.iteration){
        this.selectedJournal = this.journals[this.selectedJournalIndex];
      }

      // this.selectedJournal = [];
    },
    journals() {
      console.log("change detected");
    },
  },
  created: function () {
    if (this.journals.length === 0) {
      this.journals.push({
        id: 0,
        iteration: this.thisiteration,
        product_id: this.product.id,
        fair_product: 0,
        created_at: null,
        annotation: null,
        image: null,
        image_plain: null,
        layers_per_cc: null,
        new: 0,
        pesticides: [],
        plantheight: null,
        plantheight_incl_pot: null,
        product_state_id: this.product.product_state_id,
        quality_group_id: null,
        maturity_stage_id: null,
        product_description_id: null,
        salesenvironment_id: this.product.sale_environment_id,
        location: null,
        stock: null,
        plantsRequestIdentifier: this.plantsRequestIdentifier,
        tradeItemId: this.tradeItemId,
        updated_at: null,
      });

      this.selectedJournalIndex++;
    }
  },
  mounted: function () {
    this.selectedJournalIndex = this.journals.length - 1;
    this.selectedJournal = this.journals[this.selectedJournalIndex];
    (async () => {
      await navigator.mediaDevices.getUserMedia({audio: false, video: true});
    })();
  },

  methods: {
    updateSelected: function (newSelected) {
      this.selected = newSelected
    },
    addIteration() {
      this.journals.push({
        id: 0,
        iteration: this.thisiteration,
        product_id: this.product.id,
        created_at: null,
        image: null,
        image_plain: null,
        annotation: this.journals[this.selectedJournalIndex].annotation,
        layers_per_cc: this.journals[this.selectedJournalIndex].layers_per_cc,
        fair_product: this.journals[this.selectedJournalIndex].fair_product,
        floriday: this.journals[this.selectedJournalIndex].floriday,
        varb: this.journals[this.selectedJournalIndex].varb,
        new: this.journals[this.selectedJournalIndex].new,
        topper: this.journals[this.selectedJournalIndex].topper,
        pesticides: null,
        plantheight: this.journals[this.selectedJournalIndex].plantheight,
        plantheight_incl_pot: this.journals[this.selectedJournalIndex]
          .plantheight_incl_pot,
        product_state_id: null,
        quality_group_id: null,
        maturity_stage_id: null,
        product_description_id: null,
        salesenvironment_id: null,
        location: this.journals[this.selectedJournalIndex].location,
        stock: this.journals[this.selectedJournalIndex].stock,
        plantsRequestIdentifier: this.journals[this.selectedJournalIndex].plantsRequestIdentifier,
        tradeItemId: this.journals[this.selectedJournalIndex].tradeItemId,
        updated_at: null,
      });
      this.selectedPesticides = this.journals[this.selectedJournalIndex].pesticides;
      this.selectedProductState = this.$attrs.productstates[this.$attrs.productstates.findIndex(x => x.id === this.product.product_state_id)];
      this.selectedQualityGroup = this.$attrs.qualitygroups[this.$attrs.qualitygroups.findIndex(x => x.id === this.journals[this.selectedJournalIndex].quality_group_id)];
      this.selectedMaturityStage = this.$attrs.maturitystages[this.$attrs.maturitystages.findIndex(x => x.id === this.journals[this.selectedJournalIndex].maturity_stage_id)];
      this.selectedSaleEnvironment = this.$attrs.saleenvironments[this.$attrs.saleenvironments.findIndex(x => x.id === this.journals[this.selectedJournalIndex].sale_environment_id)];
      this.selectedProductDescription = this.$attrs.productdescriptions[this.$attrs.productdescriptions.findIndex(x => x.id === this.journals[this.selectedJournalIndex].product_description_id)];

      this.selectedJournalIndex++;
      this.$forceUpdate;
      this.$nextTick(function () {
        $(".multiple-journals").select2();
      });
      this.$forceUpdate;
    },

    save() {
      if (this.$refs.cameraChild.captured) {
        this.journals[
          this.selectedJournalIndex
        ].image = this.$refs.cameraChild.captured;
      }
      this.journals[
        this.selectedJournalIndex
      ].pesticides = this.selectedPesticides;
      this.journals[
        this.selectedJournalIndex
      ].product_state_id = (typeof this.selectedProductState !== 'undefined' ? this.selectedProductState.id : null);
      this.journals[
        this.selectedJournalIndex
      ].sale_environment_id = (typeof this.selectedSaleEnvironment !== 'undefined' ? this.selectedSaleEnvironment.id : null);
      this.journals[
        this.selectedJournalIndex
      ].quality_group_id = (typeof this.selectedQualityGroup !== 'undefined' ? this.selectedQualityGroup.id : null);
      this.journals[
        this.selectedJournalIndex
      ].maturity_stage_id = (typeof this.selectedMaturityStage !== 'undefined' ? this.selectedMaturityStage.id : null);
      this.journals[
        this.selectedJournalIndex
      ]
      .product_description_id = (typeof this.selectedProductDescription !== 'undefined' ? this.selectedProductDescription.id : null);
      axios
        .put(
          "/plants/journals/" + this.product.id,
          this.journals[this.selectedJournalIndex]
        )
        .then(
          (response) => {
            this.journals[this.selectedJournalIndex].id = response.data;
            alert("Succesvol opgeslagen");
          },
          (error) => {
            alert("Probleem bij het opslaan");
            console.log(error);
          }
        );
    },
    blurField() {
      this.editField = "";
    },
    focusField(name) {
      this.editField = name;
    },
    nextIteration: function () {
      if (this.selectedJournalIndex < this.journals.length - 1) {
        this.selectedJournalIndex++;
      }
    },
    previousIteration: function () {
      if (this.selectedJournalIndex > 0) {
        this.selectedJournalIndex--;
      }
    },
    showField(name) {
      return this.user[name] == "" || this.editField == name;
    },
    nameWithCode ({ name_nl, code }) {
      return `${code} — [${name_nl}]`
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
img {
  max-width: 50vw;
  max-height: 300px;
}
</style>
