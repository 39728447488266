<template>

    <div>
        
<div v-if="showScanner">
    <v-quagga :onDetected="logIt" :readerTypes="['ean_reader']" :cameras="cameras"></v-quagga>
    <div style="margin-top: 48em; width: 100%;" @click="hideTheScanner" class="btn btn-brand btn-sm btn-bold btn-upper">Sluit camera</div>
</div>
<div v-if="showScanner == false">
        <div class="col-lg-12 col-xl-12 order-lg-3 order-xl-1 client-list">
            <div class="kt-portlet kt-portlet--height-fluid">
                <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            <i class="fas fa-search"></i>
                        </h3>
                        <input class="form-control kt-quick-search__input" type="text" :value="query" @input="query = $event.target.value">
                        <div class="scanBtn" @click="showTheScanner"><i class="fas fa-barcode-read"></i></div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-xl-12 order-lg-3 order-xl-1">
            <div v-if="results.length > 0 && query" class="card-deck">
                <div class="card card--product" v-for="result in results" :key="result.id">
                    <a :href="'/plants/journals/' + result.searchable.id + '/edit'">
                    <div class="text-center">
                        <!-- <img class="img-fluid" :src="item.logo" width="100" alt="Card image cap"> -->
                        <div class="img-container">
                        <img class="img-fluid" :src="'/storage/' + result.searchable.image" onerror="this.onerror=null; this.src='/images/placeholder-img.jpg'" width="100%" alt="Card image cap">
                        </div>

                        <div class="card-body">
                            <h5 class="card-title">{{ result.title }}</h5>
                            <div class="card-text">
                                <div class="product-detail">
                                    <div class="product-specs product-specs__1col">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </div>

</template>

<script>
import VueQuagga from './vue-quagga.js';

// register component 'v-quagga'
Vue.use(VueQuagga);

    export default {
        name: 'VueBarcodeTest',
       data() {
         return {
             devices: [],
           selectedCamera: 0,
           firstCamera: 0,
           cameras: [],
           totalCameras: 0,
           errorCamera: 0,
           query: null,
           results: [],
           requests: [],
           request: null,
           detecteds: [],
           showScanner: false,
           readerSize: {
             width: 1920,
             height: 1080
           },
           detecteds: [],
         };
       },
       mounted() {
           this.getConnectedDevices('videoinput')
           .then((data) => {
             this.cameras = JSON.parse(JSON.stringify(data));
           })
       },
       watch: {
         query(after, before) {
            if(after != ''){
                this.searchProducts();
            }
         }
       },
       methods: {
           async getConnectedDevices(type) {
               this.devices = await navigator.mediaDevices.enumerateDevices();
               return this.devices.filter(device => (device.kind === type ))
           },
           showTheScanner(){
               this.showScanner = true;
           },
           hideTheScanner(){
               this.showScanner = false;
           },
            logIt (data) {
                let errors = data.codeResult.decodedCodes
                    .filter(_ => _.error !== undefined)
                    .map(_ => _.error);
                if(this.average(errors) < 0.17){
            this.query =  data.codeResult.code;
            this.hideTheScanner();
                }
            },
            average(array){
                return (array.reduce((a, b) => a + b) / array.length);
            },
           searchProducts() {
             if (this.request) this.cancel();
             this.makeRequest();
           },
           cancel() {
             this.request.cancel();
             this.clearOldRequest("Cancelled");
           },
           makeRequest () {
             const axiosSource = axios.CancelToken.source();
             this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
            axios.get('/search/products',
                { params: { query: this.query },
                cancelToken: axiosSource.token
             })
             .then( response => {
               this.clearOldRequest("Success");
               this.results = response.data
               this.apiLoaded = true
             }).catch(e => {
                //
             });
           },

           clearOldRequest(msg) {
             this.request.msg = msg;
             this.requests.push(this.request);
             this.request = null;
           },
       }
    }
</script>
