<template>
  <div>
    <canvas ref="canvas" id="canvas" v-if="canvasVisible"></canvas>
    <canvas ref="canvaslarge" id="canvaslarge"></canvas>
    <button v-if="canvasVisible"
      class="form-control trash-canvas"
      style="background: #64a622; color:#fff margin-top: 85vh;"
      dark
      fab
      @click="removeCanvas()"
    ><i class="fas fa-trash"></i>
    </button>
    <div class="camera-modal" v-if="newPicture">
      <fullscreen :fullscreen.sync="fullscreen">
        <video ref="video" id="video" class="camera-stream" autoplay playsinline/>
        <div class="camera-modal-container"></div>
        <button
          class="form-control capture"
          style="background: #64a622; color:#fff margin-top: 85vh;"
          dark
          fab
          @click="capture()"
        >
          <i class="fas fa-camera"></i>
        </button>
        <button
          v-if="totalCameras > 1"
          class="form-control switch-camera"
          style="background: #64a622; color:#fff margin-top: 85vh;"
          dark
          fab
          @click="nextCamera()"
        >

          <i class="fas fa-sync"></i>
        </button>
        <button
          class="form-control cancel-camera"
          style="background: #64a622; color:#fff margin-top: 85vh;"
          dark
          fab
          @click="cancelCamera()"
	>

	<i class="fas fa-times"></i>
        </button >
      </fullscreen >
    </div >
	<div class="camera-model" v-else>
		<img :src="$attrs.image" alt />
		<button
			v-if="cameras.length"
			class="form-control"
			style="background: #64a622; color:#fff"
			v-on:click="nextCamera()"
		>Take new picture</button>
		<button
			v-else
			class="form-control"
			style="background: #64a622; color:#fff"
			v-on:click="noCamera()"
		>No Camera Found</button>
	</div>

  </div >
</template >

<script>
import fullscreen from "vue-fullscreen";
import Vue from 'vue'
Vue.use(fullscreen)
export default {
  data() {
    return {
      mediaStream: null,
      canvasVisible: false,
      newPicture: false,
      fullscreen: false,
      captured: false,
      video: {},
      canvas: {},
      canvaslarge: {},
      selectedCamera: 0,
      firstCamera: 0,
      cameras: [],
      constraints: false,
      orientation: '',
      totalCameras: 0,
      errorCamera: 0,
    };
  },
  mounted() {
    // this.logLaravel("------------- Start Vue Log -------------");
      this.getConnectedDevices('videoinput')
       .then((data) => {
         this.cameras =  JSON.parse(JSON.stringify(data));
        //  this.logLaravel(this.cameras);
       })

       window.addEventListener(
         "orientationchange",
         this.handleOrientationChange
       );
       this.orientation = window.screen.orientation.type;
  },
  methods: {
    logLaravel(myJsVar){
      $.ajax({
          type: 'GET',
          url: '/logging',
          headers: {'X-Requested-With': 'XMLHttpRequest'},
          data: {value: myJsVar},
          success: function (response) {

          }
      });
    },
    async getConnectedDevices(type) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => (device.kind === type &&
          (device.label.toLowerCase().indexOf('back') !== -1 ||
            device.label.toLowerCase().indexOf('achterzijde') !== -1 ||
            device.label.toLowerCase().indexOf('obs') !== -1
            )))
    },
    async showCanvas(state) {
      this.canvasVisible = state;
      if (state == true) {
        
        this.$nextTick(() => {
          var canvaslarge = document.getElementById("canvaslarge");
          var canvas = document.getElementById("canvas");
          var ua = navigator.userAgent.toLowerCase();

          var isAndroid = ua.indexOf("android") > -1;
          console.log(isAndroid);
          if ((isAndroid && (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary")) || 
          (!isAndroid && (window.orientation == 0 || window.orientation == 180))) {
              canvas.height = 200;
              canvas.width = 150;

              canvaslarge.height = 3200;
              canvaslarge.width = 2400;
          }
          else{
            canvaslarge.height = 2400;
			      canvaslarge.width = 3200;

            canvas.height = 150;
            canvas.width = 200;
          }
        });
      }
    },
    removeCanvas(){
      this.canvas = false;
      this.canvaslarge = false;
      this.showCanvas(false);
    },
    setConstraints(cameraId){
      if (this.orientation === "landscape-primary") {
        return {
          audio: false,
          video: {
            // width: { ideal: 1600},
            // height: { ideal: 1200},
            deviceId: cameraId
          }
        }
      }else{
        return {
          audio: false,
          video: {
        //  width: { ideal: 1200},
        //  height: { ideal: 1600},
            deviceId: cameraId
          }
        }

      }
    },
    cancelCamera() {
      if(!this.captured){
        this.canvasVisible = false;
      }
        this.newPicture = false;
        this.selectedCamera--;
        this.$nextTick(() => {
          this.fullscreen = false
        });

    },
    capture() {
      this.showCanvas(false);
      this.showCanvas(true);
      this.$nextTick(() => {
        this.video = this.$refs.video;

        this.canvaslarge = this.$refs.canvaslarge;


        var canvaslarge = document.getElementById("canvaslarge");

        var context = this.canvaslarge
        .getContext("2d")
        .drawImage(this.video, 0, 0, canvaslarge.width, canvaslarge.height);

        this.captured = canvaslarge.toDataURL("image/jpeg");

        this.canvas = this.$refs.canvas;
        var canvas = document.getElementById("canvas");
        var context = this.canvas
        .getContext("2d")
        .drawImage(this.video, 0, 0, canvas.width, canvas.height);

        this.newPicture = false;
        this.selectedCamera--;
        this.$nextTick(() => {
          this.fullscreen = false
        });
      });
    },
    async newPhoto(deviceId) {
      this.$nextTick(() => {
        this.newPicture = true;
      });
      if(this.errorCamera < 25){
        var that = this;
        var timeout = setTimeout(function(){
          navigator.mediaDevices.getUserMedia(that.constraints)
            .then((mediaStream) => {
              that.$refs.video.srcObject = mediaStream
              that.totalCameras = 0
              that.$nextTick(() => {
                that.fullscreen = false
              });
              that.$nextTick(() => {
                that.fullscreen = true
            })
            .catch(
              (error) => {
                console.error("getUserMedia() error:", error);
                that.errorCamera++;
                that.newPhoto(deviceId);
                that.totalCameras = that.cameras.length;

                });
              }
            );
        }
        , 500);
      }else{
        this.cancelCamera();
        this.errorCamera = 0;
        this.selectedCamera--;
      }

    },
   async nextCamera() {
      var sel = await this.cameras;
      var i = await this.selectedCamera;
      if(this.firstCamera > 0){
        this.selectedCamera = await ++i%sel.length;
      }else{
        this.firstCamera++
        this.selectedCamera = i;
      }
      console.log(sel[this.selectedCamera].deviceId);
      this.constraints = await this.setConstraints(sel[this.selectedCamera].deviceId);
      this.newPhoto(sel[this.selectedCamera].deviceId);
    },
    async handleOrientationChange() {
      if(this.newPicture){
        this.selectedCamera--;
      this.orientation = await window.screen.orientation.type
      this.nextCamera();
      }
    },
    async noCamera(){
       await navigator.mediaDevices.getUserMedia({audio: false, video: true});
       const devices = await navigator.mediaDevices.enumerateDevices();
       console.log(devices);
    },
  },
};
</script>

<style scoped>
.camera-modal {
  background-color: black;
  position: absolute;
  max-width: 100%;
  z-index: 98;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.camera-stream {
  max-width: 100vw;
}
.camera-modal-container {
  position: absolute;
  bottom: 0;
  width: 100vw;
  align-items: center;
  margin-bottom: 24px;
}
.take-picture-button {
  display: block;
  margin: auto;
  z-index: 99;
  margin-bottom: 20px;
}

.capture {
  position: absolute;
  width: 3em;
  height: 3em;
  padding: 0;
  font-size: 2em;
  line-height: 2em;
  text-align: center;
  outline: none;
  border-radius: 50%
}

@media (orientation: landscape) {
  .capture {
    right: 1em;
    bottom: 40vh;
  }

  .camera-stream {
      max-width: 100vw;
      min-height: 100vh;
      max-height: 100vh;
  }

}

@media (orientation: portrait) {
  .capture {
    right: 40vw;
    bottom: 1em;
  }
  .camera-stream {
      max-width: 100vw;
      min-width: 100vw;
      max-height: 100vh;
  }
}

.camera-stream {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.switch-camera {
position: absolute;
left: 10px;
top: 10px;
width: 2em;
height: 2em;
padding: 0;
font-size: 2em;
line-height: 2em;
text-align: center;
outline: none;
}
.cancel-camera {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  outline: none;
}
.fullscreen {
  overflow: hidden !important;
}


  #canvaslarge {
    display: none;
      width: 1920px;
      height: 1080px;
}
</style>