'use strict'
global.jQuery = global.$ = require('jquery');
require('./bootstrap');
import '../flaire/tools/webpack/vendors/global';
import '../flaire/tools/webpack/scripts';

Dropzone.autoDiscover = false;

window.Vue = require('vue');
Vue.component('product-search-component', require('./components/product/SearchPage.vue').default);
Vue.component('plant-journal-edit-component', require('./components/plants/journals/EditPage.vue').default);
Vue.component('camera', require('./components/Camera.vue').default);
const app = new Vue({
    el: '#app',
})
